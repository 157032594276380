<!-- 等级编辑弹窗 -->
<template>
  <div class="ele-body">
    <el-card shadow="never">
      <div style="margin-bottom: 20px" class="el-dialog__header">
        <span class="el-dialog__title">参数配置</span>
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="82px">
        <el-form-item label="训练目标:" prop="training">
          <el-input
            v-model="form.training"
            placeholder="请输入训练目标"
            clearable
            :rows="3"
            type="textarea"
          />
        </el-form-item>

        <el-form-item label="课时安排:" prop="arrange">
          <el-input
            v-model="form.arrange"
            placeholder="请输入课时安排"
            clearable
            :rows="3"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="测试提示:" prop="demand">
          <tinymce-editor class="tinymce-editor" v-model="form.demand"/>
        </el-form-item>
        <el-form-item
                label="做题提示:"
                prop="reminder">
          <el-input
                  v-model="form.reminder"
                  placeholder="请输入做题提示"
                  clearable
                  :rows="3"
                  type="textarea"/>
        </el-form-item>
        <el-form-item
          v-for="(item, i) in form.roleList"
          :key="i"
          prop="play_speed"
        >
          <el-row type="flex">
            <el-col :span="4">等级: {{ item.name }} </el-col>

            <el-col :span="5">
              <el-form-item label="播放速度:">
                <el-input
                  placeholder="请输入播放速度"
                  v-model="item.speedNum"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <!-- <el-form-item label="播放速度" prop="play_speed">
          <el-col :span="18">
            <el-input v-model.number="form.play_speed" placeholder="请输入播放速度" type="number" ></el-input>
          </el-col>
        </el-form-item> -->
        <!--        <el-row v-for="(item,index) in form.numberTime" :key="index">
          <el-col :span="8">
            <el-form-item
              label="时间"
              :prop="'numberTime.' + index + '.time'"
              :rules="{required: true, message: '请输入时间', trigger: 'blur'}"
            >
                <el-input v-model="item.time" placeholder="请输入时间"  type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="字数"
              :prop="'numberTime.' + index + '.number'"
              :rules="{required: true, message: '请输入字数', trigger: 'blur'}"
            >
                <el-input  v-model="item.number" placeholder="请输入字数"  type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>-->

        <!-- <el-form-item>
          <el-button type="danger" @click="removeOptions">删除</el-button>
          <el-button type="primary" @click="addOptions">新增</el-button>
        </el-form-item> -->

        <el-form-item label="备注:">
          <el-input
            v-model="form.remark"
            placeholder="请输入备注"
            :rows="4"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div>
        <el-button
          style="float: right; margin-bottom: 20px"
          type="primary"
          @click="save"
          :loading="loading"
          >保存
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
  import TinymceEditor from '@/components/TinymceEditor';
export default {
  name: "configEdit",
  props: {},
  components: {TinymceEditor},
  data() {
    this.$http["get"]("/Admin/visionSpanConfigEdit").then((res) => {
      this.data = res.data.data;
      // 如果后台的配置项没有 roleList 调用查看用户等级接口 选取默认的列表
      this.queryRoles(this.data.roleList);
      if (!this.data) {
        this.data = { is_group: 0 };
      } else {
        this.isUpdate = true;
        this.isShowNum = this.data.is_group;
        this.groupNum = this.data.group_num;
      }

      this.form = Object.assign(this.data);
    });
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        training: [
          { required: true, message: "请输入训练目标", trigger: "blur" },
        ],
        arrange: [
          { required: true, message: "请输入课程安排", trigger: "blur" },
        ],
        // play_speed: [
        //   { required: true, message: "请输入播放速度", trigger: "blur" },
        // ],
        speedNum: [
          { required: true, message: "请输入播放速度", trigger: "blur" },
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 是否显示每组数量
      isShowNum: 0,
      // 每组数量
      groupNum: 0,
    };
  },
  methods: {
    /* 保存编辑 */
    save() {
      //return
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          // 判断是否分组 选择否 条件成立
          if (!this.form.is_group) {
            this.form.group_num = 0;
          }
          this.$http[this.isUpdate ? "put" : "post"](
            "/Admin/saveVisionConfig",
            this.form
          )
            .then((res) => {
              this.loading = false;
              if (res.data.code === 200) {
                this.$message({ type: "success", message: res.data.msg });
                // 提交成功后，如果没有默认显示时间，解开以下注释
                // this.form.numberTime = [
                //   {time:null, number:null},
                // ]
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message.error(e.message);
            });
        } else {
          return false;
        }
      });
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
    // 改变分组状态
    changeRadio(e) {
      this.isShowNum = e;
      this.$set(this.form, "group_num", this.groupNum);
    },
    // 添加
    // addOptions() {
      // console.log(this.form.numberTime);
      // this.form.numberTime.push({ time: null, number: null });
      // this.ruleForm.domains[index].options.push({
      //   chioptions: '',
      //   radio: 0,
      //   key: Date.now()
      // })
    // },
    // 删除
    // removeOptions() {
    //   this.form.numberTime.length > 1 && this.form.numberTime.pop();
    // },
    /* 查询等级列表 */
    queryRoles(roleList) {
      if (!roleList) {
        this.$http
          .get("/Admin/level")
          .then((res) => {
            if (res.data.code === 200) {
              this.form.roleList = res.data.data.map((obj) => {
                return { name: obj.name, id: obj.id, speedNum: 1 };
              });
              this.form = Object.assign(
                {
                  // 时间和字数 numberTime
                  roleList: this.roleList,
                },
                this.data
              );
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>
